.device-container {
    // background-color: white;
    min-height: 700px;
    padding: 10px;
    .dbox-list-container, .sensor-list-container {
        margin-bottom: 40px;
        background-color: white;
        padding: 10px;
        .title {
            font-size: 16px;
            border-left: 2px solid @text-color;
            padding-left: 4px;
            line-height: 1;
            margin: 10px;
        }
    }

    .ant-table-thead>tr>th {
        padding: 8px 16px;
        background: #eef1f6;
    }

    .ant-table-tbody>tr>td {
        padding: 6px 16px;
    }
}
@primary-color: #0168B3;@text-color: #222a53;
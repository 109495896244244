.dashboard-dark-container {

    width: 100vw;
    height: 100vh;
    background: url("dashboard-assets/background.png");
    background-repeat: repeat;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .dashboard-header {
        width: 100%;
        height: 60px;
        flex: 0 0 60px;
        background: url("dashboard-assets/header.png");
        background-size: 100%;
        text-align: center;
        margin-bottom: 10px;
        display: flex;
        position: relative;
        .title {
            line-height: 60px;
            font-size: 24px;
            cursor: pointer;
            background-image: linear-gradient(#7bc4f8, #ffffff);
            color: transparent;
            background-clip: text;
            text-align: center;
            margin: 0 auto;
        }

        .backend-btn {
            position: absolute;
            background-clip: text;
            text-align: center;
            line-height: 60px;
            font-size: 16px;
            left: 10px;
            cursor: pointer;
            background-image: linear-gradient(#7bc4f8, #ffffff);
            color: transparent;
        }

        .video-btn {
            position: absolute;
            // background-clip: text;
            text-align: center;
            // line-height: 60px;
            font-size: 16px;
            right: 10px;
            top: 15px;
        }
    }

    .dashboard-main {
        flex: 1 1 auto;
        display: flex;
        padding: 10px;
        padding-top: 0px;

        .other-title {
            font-size: 18px;
            color: #7bc4f8;
            position: relative;
            top: 16px;
            left: 80px;
            cursor: pointer;
        }

        .dashboard-chart-panel {
            flex: 0 0 450px;
            overflow: hidden;
            display: flex;
            flex-direction: column;

            .device-chart-panel {
                height: 180px;
                background: url("dashboard-assets/frame.png");
                background-size: cover;
                color: #7bc4f8;
                font-size: 12px;

                .device-chart {
                    position: relative;
                    top: 26px;
                    display: flex;
                    padding: 0 12px;
                    display: flex;
                }
            }

            .quantity-chart-panel {
                margin-top: 10px;
                flex: 1 1 auto;
                display: flex;
                flex-direction: column;
                // justify-content: space-between;
                background: url("dashboard-assets/frame1_middle_left.png") left no-repeat,
                    url("dashboard-assets/frame1_middle_right.png") right no-repeat;
                background-size: auto 100%;

                .frame-top {
                    height: 60px;
                    background: url("dashboard-assets/frame2_top.png");
                    background-size: cover;
                }

                .quantity {
                    max-height:600px;
                }

                .ant-row {
                    padding: 0 10px;
                }

                .ant-tabs-bar {
                    background: #062047;
                    font-size: 12px;
                    color: #7bc4f8;
                    margin-left: 3px;
                    margin-right: 3px;
                    margin-bottom: 3px;
                    border-bottom: none;

                    .anticon {
                        color: #7bc4f8;
                    }
                }

                .ant-tabs-nav .ant-tabs-tab {
                    padding: 7px 0;
                }

                .ant-col {
                    flex: 0 0 100%;
                    max-width: 100%;
                }

                .legend {
                    font-size: 12px;
                    color: #1389c9;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;

                    .legend-group {
                        margin: 0 4px;
                    }

                    .title {
                        background: #062047;
                        color: #7bc4f8;
                        padding: 1px 10px;
                        height: 26px;
                        border: none;
                        // pointer-events: none;
                    }
                }

                .frame-bottom {
                    height: 32px;
                    background: url("dashboard-assets/frame2_bottom.png");
                    background-size: cover;
                }



            }
        }

        .dashboard-project-panel {
            margin: 0 10px;
            flex: 1 1 auto;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .project-info-panel {

                .center-top {
                    background: url("dashboard-assets/center_top.png");
                    background-size: cover;
                    text-align: center;

                    .title {
                        line-height: 60px;
                        font-size: 24px;
                        background-image: linear-gradient(#7bc4f8, #ffffff);
                        color: transparent;
                        background-clip: text;
                    }
                }

                .drawing-panel {
                    height: 450px;
                    // width: 600px;
                    margin: 10px;
                    position: relative;

                    background: url("dashboard-assets/frame1_top_left.png") left top no-repeat,
                        url("dashboard-assets/frame1_top_center.png") left top no-repeat,
                        url("dashboard-assets/frame1_top_right.png") right top no-repeat,
                        url("dashboard-assets/frame1_middle_center.png") left top repeat,
                        url("dashboard-assets/frame1_bottom_left.png") left bottom no-repeat,
                        url("dashboard-assets/frame1_bottom_center.png") bottom no-repeat,
                        url("dashboard-assets/frame1_bottom_right.png") right bottom no-repeat;
                    background-size: 5%;

                    .other-title {
                        position: absolute;
                        top: 10px;
                        left: 60px;
                        z-index: 100;
                    }

                    .figure {
                        height: 450px;
                        display: flex;
                        justify-content: center;
                        cursor: pointer;
                    }

                    .img {
                        object-fit: contain;
                    }
                }

            }

            .quantity-evaluate-panel {
                margin: 10px;
                margin-top: 0px;
                margin-bottom: 0px;
                flex: 1 1 auto;
                background: url("dashboard-assets/frame1_top_left.png") left top no-repeat,
                    url("dashboard-assets/frame1_top_center.png") left top no-repeat,
                    url("dashboard-assets/frame1_top_right.png") right top no-repeat,
                    url("dashboard-assets/frame1_middle_center.png") left top repeat,
                    url("dashboard-assets/frame1_bottom_left.png") left bottom no-repeat,
                    url("dashboard-assets/frame1_bottom_center.png") bottom no-repeat,
                    url("dashboard-assets/frame1_bottom_right.png") right bottom no-repeat;
                background-size: 5%;

                .other-title {
                    top: 10px;
                    left: 60px;
                }


                .evaluate-header {
                    display: none;
                }

                .evaluate-content {
                    height: 100%;

                    .evaluate-table {
                        .ant-table {
                            background: transparent;
                        }

                        .ribbon-row {
                            background: transparent;
                        }

                        .ant-table-thead>tr>th {
                            padding: 3px 8px;
                            background: #062047;
                            color: #7bc4f8;
                            font-size: 12px;
                        }

                        .ant-table-tbody>tr>td {
                            padding: 3px 8px;
                            border: none;
                            font-size: 12px;
                            color: white;
                        }

                        // .ant-table-tbody>tr {
                        //     &:hover {
                        //         background-color: transparent;
                        //     }
                        // }

                        .ant-table-tbody > tr.ant-table-row:hover > td {
                            background-color: rgba(25,67,129, 0.5);
                        }
                    }

                    .evaluate-statistic {
                        display: none;
                    }

                    .evaluate-statistic .pane {
                        height: 35px;
                        margin-bottom: 15px;
                    }
                }

                .main {
                    display: flex;
                    width: 100%;
                    height: calc(~'100% - 35px');
                    padding: 5px 10px;

                    .evaluate {
                        flex: 1 1 auto;
                    }

                    .project-info {
                        width: 350px;
                        display: flex;
                        flex-direction: column;
                        margin-left: 15px;

                        .evaluation-info,
                        .monitor-info {
                            flex: 1;
                            background: url("dashboard-assets/frame1_top_left.png") left top no-repeat,
                                url("dashboard-assets/frame1_top_center.png") left top no-repeat,
                                // url("dashboard-assets/frame1_top_right.png") right top no-repeat,
                                // url("dashboard-assets/frame1_bottom_left.png") left bottom no-repeat,
                                url("dashboard-assets/frame1_bottom_center.png") bottom no-repeat,
                                url("dashboard-assets/frame1_bottom_right.png") right bottom no-repeat;
                            background-size: 5%;
                            margin-bottom: 10px;
                        }

                        .evaluation-info {
                            text-align: center;

                            .other-title {
                                top: -8px;
                                left: -85px;
                            }

                            .evaluation {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: calc(~'100% - 28px');

                                .evaluation-text {
                                    margin-left: 15px;
                                    font-size: 24px;
                                    font-weight: 600;
                                }
                            }
                        }

                        .monitor-info {
                            text-align: center;

                            .other-title {
                                top: -8px;
                                left: -103px;
                            }

                            .ant-descriptions-row>th,
                            .ant-descriptions-row>td {
                                padding-bottom: 10px;
                            }

                            .ant-descriptions-item-label {
                                background-color: #051f45;
                            }

                            .ant-descriptions-item-label,
                            .ant-descriptions-item-content {
                                width: 100px;
                                font-size: 14px;
                                color: #1389c9;
                            }
                        }
                    }
                }


            }

            .frame-bottom {
                height: 32px;
                background: url("dashboard-assets/frame2_bottom.png");
                // background-size: cover;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
        }

        .dashboard-evaluate-panel {
            flex: 0 0 450px;
            overflow: hidden;

            display: flex;
            flex-direction: column;

            .history-warning-panel,
            .device-exception-panel {
                height: 275px;
                margin-bottom: 10px;
                background: url("dashboard-assets/frame.png");
                background-size: cover;
            }

            .warning-list,
            .exception-list {
                position: relative;
                margin-top: 30px;
                padding: 0 40px;
                height: calc(~'100% - 65px');
                overflow: auto;

                &::-webkit-scrollbar {
                    display: none;
                    /* Chrome Safari */
                }

                scrollbar-width: none;
                /* firefox */
                -ms-overflow-style: none;
                /* IE 10+ */

                .alert-item {
                    color: white;
                    width: 100%;
                    cursor: pointer;
                }
            }

            .document-panel {
                flex: 1 1 auto;
                max-height: 315px;
                overflow: auto;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                background: url("dashboard-assets/frame1_middle_left.png") left no-repeat,
                    url("dashboard-assets/frame1_middle_right.png") right no-repeat;

                .frame-top {
                    height: 65px;
                    background: url("dashboard-assets/frame2_top.png");
                    background-size: cover;
                }

                .frame-bottom {
                    height: 32px;
                    background: url("dashboard-assets/frame2_bottom.png");
                    background-size: cover;
                }

                .document-list {
                    position: relative;
                    padding: 0 40px;
                    height: 100%;
                    overflow: auto;

                    &::-webkit-scrollbar {
                        display: none;
                        /* Chrome Safari */
                    }

                    scrollbar-width: none;
                    /* firefox */
                    -ms-overflow-style: none;

                    /* IE 10+ */
                    .report-item {
                        font-size: 12px;
                        color: #1389c9;

                        display: block;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;

                        &:hover {
                            cursor: pointer;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}
@primary-color: #0168B3;@text-color: #222a53;

.constage-add-container {
    margin-bottom: 20px;
    background-color: white;
    padding: 10px;
    margin-left: 26px;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;
        border-bottom: 1px solid #eaeaea;
        .title {
            padding-left: 6px;
            border-left: 2px solid @text-color;
            line-height: 1.2;
            font-size: 16px;
        }
    }
}
@primary-color: #0168B3;@text-color: #222a53;
.dbox-detail-container {

    .card {
        background-color: white;
        height: 300px;

        &.properties {
            display: flex;

            .info {
                display: flex;

                >div {
                    width: 50%;
                }

                .operation-pane {
                    padding-top: 44px;

                    .term {
                        display: flex;
                        align-items: center;
                        min-width: 160px;
                        margin-bottom: 12px;
                        .label {
                            // font-weight: 600;
                            min-width: 85px;
                            line-height: 32px;
                            margin-right: 20px;
                            text-align: right;
                            &:not(.no-colon)::after {
                                content: ':';
                            }
                        }

                        .detail {
                            display: block;
                            white-space: pre-wrap;
                        }
                    }
                }
            }
        }
    }


}
@primary-color: #0168B3;@text-color: #222a53;

.material-add-container {
    background-color: white;
    min-height: 500px;
    padding: 10px 20px 30px;

    .page-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .main {
        margin-top: 10px;
        .section-library {
            border: 1px solid @text-color;
            border-radius: 8px;
            padding: 24px;
            min-height: 500px;
            margin-bottom: 12px;
        }

        .section-selected {
            padding: 0 0 24px;
            .title {
                padding-left: 4px;
                border-left: 2px solid @text-color;
                line-height: 1;
                margin-bottom: 10px;
            }
        }
    }

    .ant-table-thead>tr>th {
        padding: 8px 16px;
        background: #eef1f6;
    }

    .ant-table-tbody>tr>td {
        padding: 6px 16px;
    }
}
@primary-color: #0168B3;@text-color: #222a53;

.node-page-container {
    background-color: white;
    min-height: 500px;
    padding: 10px 20px;

    .header {
        text-align: right;
        margin-bottom: 10px;
    }


    .ant-table-thead>tr>th {
        padding: 8px 16px;
        background: #eef1f6;
    }

    .ant-table-tbody>tr>td {
        padding: 3px 16px;
    }

    // .ribbon-row {
    //     // background: #eef1f6;
    // }


    .editable-cell {
        position: relative;
      }
      
      .editable-cell-value-wrap {
        padding: 5px 12px;
        cursor: pointer;
        &:hover {
            border: 1px solid #d9d9d9;
            border-radius: 4px;
            padding: 4px 11px;
        }
      }

}
@primary-color: #0168B3;@text-color: #222a53;
.process-bar {
    margin-top: 12px;
    .info {
        display: flex;
        justify-content: space-between;
        >span {
            display: block;
        }
    }
}
@primary-color: #0168B3;@text-color: #222a53;

.global-section-container {
    background-color: white;
    .section-library {
        border: 1px solid @text-color;
        border-radius: 8px;
        padding: 24px;
        min-height: 500px;
        margin-bottom: 12px;
    }



    .ant-table-thead>tr>th {
        padding: 8px 16px;
        background: #eef1f6;
    }

    .ant-table-tbody>tr>td {
        padding: 6px 16px;
    }
}
@primary-color: #0168B3;@text-color: #222a53;
.dashboard-card.alert {
    .ant-tabs-bar {
        margin: 0 0 4px;
    }

    .alert-item {
        width: 100%;
        cursor: pointer;
        &:hover {
            background-color: #f8f8f8;
        }
        // display: flex;
        // justify-content: space-between;
        >span {
            display: inline-block;
            // margin-right: 16px;
        }
    }

}
@primary-color: #0168B3;@text-color: #222a53;
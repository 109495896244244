
.constage-mobject-container {
    background-color: white;
    padding: 10px;
    height: calc(100% - 10px);
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #eaeaea;
        padding: 10px 0;
        .title {
            border-left: 2px solid @text-color;
            padding-left: 4px;
            line-height: 1.2;
        }
    }
    .mobject-group-container {
        overflow: auto;
        // max-height: 600px;
        // margin-bottom: 10px;
        // margin-top: 10px;
        padding: 10px 0;
    }
    .mobject-button {
        height: 32px;
        // padding: 6px 16px;
        background-color: #cce1f1;
        color: @primary-color;
        font-weight: 600;
        line-height: 32px;
        text-align: center;
        width: 150px;
        margin-bottom: 6px;
        cursor: pointer;
        transition: all .3s;
        border-radius: 4px;
        margin-right: 10px;
        &:hover:not(.mobject-button-active) {
            background-color: #d9e9f5;
        }
        &-active {
            background-color: @primary-color;
            color: white;
        }
    }

    .mobject-quantity {
        padding: 10px;
        text-align: center;
        flex: 1;
        // margin: 0 20px;
    }

    .quantity-list .quantity-item {
        padding: 4px 16px;
        margin: 0 20px;
        background-color: #eef1f6;
    }
}
@primary-color: #0168B3;@text-color: #222a53;

.setting-project-container {
    min-height: 700px;
    background-color: white;
    padding: 20px;

    .warning-user-pane {
        margin-top: 50px;
        max-width: 700px;
        .header {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            .title {
                margin-right: 8px;
            }
        }

        .ant-table-thead>tr>th {
            padding: 8px 16px;
            background: #eef1f6;
        }
    
        .ant-table-tbody>tr>td {
            padding: 6px 16px;
        }
    }

    .clean-pane {

    }

    .clean-btn {
        margin: 0 20px;
    }

}

.select-warning-user-table {
    height: 400px;
    overflow: auto;
    .ant-table-thead>tr>th {
        padding: 8px 16px;
        background: #eef1f6;
    }

    .ant-table-tbody>tr>td {
        padding: 6px 16px;
    }
}
@primary-color: #0168B3;@text-color: #222a53;
.line-chart-container {
    // display: flex;
    width: 100%;
    margin: 16px 0;
    padding-top: 16px;
    padding-right: 16px;
    border: 1px dashed #b8c4e0;
    // align-items: flex-start;

    // .line-chart-canvas {
    //     // width: 90%;
    // }
    // .legend-container {
    //     // width: 20%;
    //     width: 140px;
    //     padding: 0 4px;

    //     .title {
    //         margin: 0 auto;
    //         border: 1px solid @primary-color;
    //         background-color: rgb(230, 243, 252);
    //         color: @primary-color;
    //         border-radius: 4px;
    //         line-height: 1.6;
    //         text-align: center;
    //     }

    //     .legend-group {
    //         display: flex;
    //         >div {
    //             line-height: 25px;
    //         }
    //         .label::after {
    //             content: ':';
    //             margin-right: 5px;
    //         }
    //     }
    // }

    .legend {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 16px 0;



        .legend-group {
            display: flex;
            margin: 0 8px;
            >div {
                line-height: 25px;
            }
            .label::after {
                content: ':';
                margin-right: 5px;
            }
        }
    }

    .line-chart-header {
        display: flex;
        justify-content: center;
        .title {
            // margin: 0 auto;
            border: 1px solid @primary-color;
            background-color: rgb(230, 243, 252);
            color: @primary-color;
            border-radius: 4px;
            line-height: 1.6;
            text-align: center;
            padding: 4px 16px;

            cursor: pointer;
            user-select: none;
            &:active {
                background-color: #cae6fa;
            }
            display: flex;
        }
    }
}

.update-quantity-delta {
    .ant-modal-close {
        display: none;
    }
    .ant-modal-header {
        padding: 4px;
    }

    .ant-modal-body {
        padding: 8px;
    }

    .ant-form-item {
        margin-bottom: 12px;
    }
}
@primary-color: #0168B3;@text-color: #222a53;
@import '~antd/dist/antd.less';
@import './color/colors.less';
@import '~katex/dist/katex.min.css';
@import '~video-react/dist/video-react.css';

.tn-base {
    position: fixed;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background:#DEF3F4;
    z-index: 99;
    overflow-x: auto;
}

.fill-remaining-space {
    flex: 1 1 auto;
}

.ellipsis-span {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
@primary-color: #0168B3;@text-color: #222a53;
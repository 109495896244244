.line-chart-dashboard-container {

    .legend {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        
        .title {
            // margin: 0 auto;
            padding: 4px 16px;
            border: 1px solid @primary-color;
            background-color: rgb(230, 243, 252);
            color: @primary-color;
            border-radius: 4px;
            // line-height: 1.6;
            text-align: center;
            height: 32px;
            cursor: pointer;
            user-select: none;
            &:active {
                background-color: #cae6fa;
            }
            >span {
                line-height: 24px;
            }
        }

        .legend-group {
            display: flex;
            margin: 0 8px;
            >div {
                line-height: 25px;
            }
            .label::after {
                content: ':';
                margin-right: 5px;
            }
        }
    }
}
@primary-color: #0168B3;@text-color: #222a53;
.site-condition-container {

    .ant-timeline-item-tail {
        border-left-color: #7cb5df;
        top:20px;
    }
    .ant-timeline-item {
        padding-bottom: 15px;
    }
    .ant-timeline-item-head {
        top: 10px
    }

    .condition-header {
        background-color: white;
        border-radius: 4px;
        box-shadow: 0 0 2px 2px rgba(234, 234, 234, 0.50);
        display: flex;
        align-items: center;
        padding: 20px;
        margin-bottom: 20px;
        margin-left: 26px;
        >.title {
            padding-left: 4px;
            border-left: 2px solid @primary-color;
            line-height: 1;
            font-size: 16px;
        }

        .tag-group {
            margin-left: 16px;

            .ant-tag {
                font-size: 14px;
                padding: 2px 7px;
            }

            .site-tag-plus {
                background: #fff;
                border-style: dashed;
                cursor: pointer;
            }
        }
    }

    .condition-add-container {
        background-color: white;
        border: 1px dashed @primary-color;
        border-radius: 4px;
        box-shadow: 0 0 2px 2px rgba(234, 234, 234, 0.50);
        margin-bottom: 20px;
        margin-left: 26px;
        min-height: 130px;
        padding: 14px;
        position: relative;
        
        .tag-group {
            margin-bottom: 10px;
            .ant-tag {
                font-size: 16px;
                padding: 4px 7px;
            }

            .site-tag-plus {
                background: #fff;
                border-style: dashed;
                cursor: pointer;
            }
        }

        .figure-add-group {
            margin-top: 10px;
        }

        .operations {
            position: absolute;
            right: 14px;
            bottom: 14px;
        }
    }

    .condition-item-group {
        .condition-item {
            background-color: white;
            border-radius: 4px;
            box-shadow: 0 0 2px 2px rgba(234, 234, 234, 0.50);
            margin-bottom: 10px;
            min-height: 130px;
            padding: 14px;
            display: flex;
            position: relative;

            .condition-date {
                // display: flex;
                // align-items: flex-start;
                margin-right: 24px;
                min-width: 110px;
                // .date-head {
                //     color: @primary-color;
                //     border: 2px solid @primary-color;
                //     border-radius: 100px;
                //     height: 10px;
                //     width: 10px;
                //     margin: 6px;
                // }
            }
            .item-main {
                // max-width: 900px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-bottom: 24px;
                // flex: 1;
                // margin-right: 24px;
                .remark {
                    display: flex;
                    >.name {
                        margin-right: 16px;
                    }
                }
                .content {
                    // max-width: 900px;
                    margin-bottom: 24px;
                }
            }
            .figures {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .figure-shell {
                    flex:  0 140px;
                    position: relative;
                    cursor: pointer;
                    &:hover {
                        .figure::before, .figure-actions {
                            opacity: 1;
                        }
                    }

                    .figure-actions {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        z-index: 10;
                        white-space: nowrap;
                        transform: translate(-50%,-50%);
                        opacity: 0;
                        transition: all .3s;
            
                        .eye {
                            color: rgba(255,255,255,0.85);
                            font-size: 28px;
                            cursor: pointer;
                            margin: 0 16px;
                        }
                    }
                }
                .figure {
                    width: 100%;
                    padding-bottom: 75%;
                    position: relative;
                    overflow: hidden;
                    background-color: #d8d8d8;
                    border-radius: 8px;
                    border: 1px solid #d8d8d8;
                    // margin: 0 10px;
                    &::before {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0,0,0,0.5);
                        opacity: 0;
                        z-index: 1;
                        transition: all, .3s;
                        content: ' ';
                    }
                    >img {
                        top: 0;
                        left: 0;
                        position: absolute;
                        // border-radius: 8px;
                    }
                }
            }
        }
    }
}
@primary-color: #0168B3;@text-color: #222a53;
.dashboard-card.report {
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #f0f0f0;
        // margin-top: -6px;

        .title {
            border-left: 3px solid @text-color;
            padding-left: 9px;
            font-size: 16px;
            margin-left: 2px;
            font-weight: bold;
        }

    }

    .report-pane {
        padding: 0 12px;
    }

    .report-item {
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
}
@primary-color: #0168B3;@text-color: #222a53;
.mobject-info-container {

    .mobject-info-panel {
        .operations {
            text-align: right;
            margin-top: 12px;
        }

        
    }

    .info-panel-group {
        // display: flex;
        // justify-content: space-around;

        .title {
            border-left: 2px solid @text-color;
            padding-left: 4px;
            line-height: 1.2;
            margin-bottom: 8px;
            font-size: 16px;
        }

        .info-panel {
            // width: 50%;
            // padding: 0 32px;
            
            .info-item {
                display: flex;
                flex-wrap: wrap;
                margin: 6px 0;
                >.label {
                    margin-right: 20px;
                    min-width: 50px;
                    font-weight: 600;
                    &::after {
                        content: ':';
                    }
                    &.short {
                        margin-right: 10px;
                        min-width: 60px;
                    }
                }

            }
        }

        .info-panel.constage {

            .content {
                display: flex;
                // flex-direction: column;
                flex-wrap: wrap;
                align-items: flex-start;
            }
            .constage-tag {
                margin: 4px 0;
                margin-right: 7px;
                border: 1px solid #d9d9d9;
                border-radius: 2px;
                background-color: #fafafa;
                padding: 0 7px;
            }
        }

        &.pattern {
            display: block;
            padding: 24px 30px;

            .pattern-item.pattern-item-horizon {
                display: flex;
            }

            .pattern-name {
                font-weight: 600;
            }
        }

        // &.constage {
        //     padding-left: 30px;
        //     .info-item {
        //         display: flex;
        //         margin: 6px 0;
        //         align-items: flex-start;
        //         // flex-wrap: wrap;

        //         >.label {
        //             margin-right: 20px;
        //             // min-width: 50px;
        //             font-weight: 600;
        //             flex: 1 0 50px;
        //             &::after {
        //                 content: ':';
        //             }
        //             &.short {
        //                 margin-right: 0;
        //             }
        //         }

        //         .content {
        //             display: flex;
        //             flex-wrap: wrap;
                    
        //         }
        //         .constage-tag {
        //             margin-bottom: 10px;
        //         }
        //     }
        // }




    }

    .can-click {
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }

    .mobject-info-figure {
        // padding: 0 30px;
        .figure {
            width: 100%;
            padding-bottom: 75%;
            position: relative;
            overflow: hidden;
            background-color: #d8d8d8;
            // border: 1px solid #d8d8d8;
            // border-radius: 28px;
            >img {
                top: 0;
                left: 0;
                position: absolute;
            }
        }
    }
}
@primary-color: #0168B3;@text-color: #222a53;
@t5-color: #e45331;
@t4-color: #f68240;
@t3-color: #efa829;
@t2-color: #4fb981;
@t1-color: #00944a;

.dashboard-card.evaluate {
    .evaluate-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // border-bottom: 1px solid #eaeaea;
        >.title {
            border-left: 3px solid @text-color;
            padding-left: 9px;
            font-size: 16px;
            margin-left: 2px;
            font-weight: bold;
        }
    }
    .evaluate-content {
        display: flex;
        padding: 8px 12px;
        .evaluate-table {
            // width: 70%;
            flex: 1 1 auto;
            .ribbon-row {
                background: #f6f7fa;
            }
            .row-click {
                cursor: pointer;
            }

            .ant-table-thead > tr > th {
                padding: 8px 16px;
                background: #eef1f6;
            }

            .ant-table-tbody > tr > td {
                padding: 6px 16px;
                border-color: #dadadada;
            }
            
        }
        .evaluate-statistic {
            width: 80px;
            margin: auto 0 auto 24px;

            .pane {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 50px;
                background-color: #eef1f6;
                margin-bottom: 20px;
                box-shadow:0 1px 4px 2px rgba(238,238,238,0.50);
                border-radius: 4px;
                font-weight: 600;
                &.t5 {
                    // border: 2px solid @t5-color;

                    // background: #fa7a5d;

                    >div {
                        color: @t5-color;
                    }
                }
                &.t4 {
                    // border: 2px solid @t4-color;
                    >div {
                        color: @t4-color;
                    }
                }
                &.t3 {
                    // border: 2px solid @t3-color;
                    >div {
                        color: @t3-color;
                    }
                }
                &.t2 {
                    // border: 2px solid @t3-color;
                    >div {
                        color: @t2-color;
                    }
                }
                &.t1 {
                    // border: 2px solid @t3-color;
                    >div {
                        color: @t1-color;
                    }
                }
                .title {
                    font-size: 14px;
                }
                .count {
                    font-size: 24px;
                }
            }
        }
    }
}
@primary-color: #0168B3;@text-color: #222a53;
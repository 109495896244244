@t5-color: #e45331;
@t4-color: #f68240;
@t3-color: #efa829;

.dashboard-evaluate-card.main {
    padding: 16px 0px;
    padding-left: 576px;
    min-height: 700px;
    .header {
        text-align: right;
        padding: 10px 0;
    }

    .evaluate-content {
        display: flex;
        padding: 8px 0px;

        .evaluate-table {
            width: 100%;

            .ribbon-row {
                background: #eef1f6;
            }

            .row-click {
                // cursor: pointer;
                .locale-btn {
                    display: none;
                }
                &:hover {
                    .locale-btn {
                        display: inline-block;
                    }
                }
            }

            .ant-table-thead>tr>th {
                padding: 8px 16px;
                background: #eef1f6;
            }

            .ant-table-tbody>tr>td {
                padding: 6px 16px;
                border-color: #dadadada;
            }


        }
    }

}
@primary-color: #0168B3;@text-color: #222a53;
.device-figure-container {
    // background-color: #eaeaea;
    height: 100%;
    width: 400px;
    padding: 8px;
    margin: 0 auto;
    .figure-card {
        height: 100%;
        position: relative;
        background-color: #eaeaea;
        &:hover {
            .figure-card-item::before, .figure-card-item-actions {
                opacity: 1;
            }
        }
        .figure-card-item {
            background-color: #e8e8e8;
            border: 1px solid #a8a8a8;
            position: relative;
            height: 100%;
            overflow: hidden;
            >img {
                top: 0;
                left: 0;
                position: absolute;
            }
            &::before {
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,0.5);
                opacity: 0;
                z-index: 1;
                transition: all, .3s;
                content: ' ';
            }
            &.uploading {
                display: none;
            }
        }
        .spin {
            display: none;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
        .spin.uploading {
            display: block;
        }

        .figure-card-item-actions {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 10;
            white-space: nowrap;
            transform: translate(-50%,-50%);
            opacity: 0;
            transition: all .3s;

            .eye, .upload {
                color: rgba(255,255,255,0.85);
                font-size: 28px;
                cursor: pointer;
                margin: 0 16px;
            }
        }
    }

}
@primary-color: #0168B3;@text-color: #222a53;
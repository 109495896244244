.section-panel {
    margin: 0 auto;
    background-color: white;
    padding: 0 20px 20px;
    // box-shadow: 0 4px 8px 2px rgba(223, 222, 222, 0.5);
    min-width: 700px;
    transition: all .4s;

    .operations {
        // text-align: right;
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .top {
        display: flex;
        justify-content: space-around;
        // padding-top: 10px;
    }

    .figure-shell {
        flex: 0 1 250px;

        .figure {
            width: 100%;
            padding-bottom: 75%;
            position: relative;
            overflow: hidden;
            background-color: #d8d8d8;
            // margin: 0 10px;
            >img {
                top: 0;
                left: 0;
                position: absolute;
                border-radius: 8px;
            }
        }
    }

    .properties {
        flex: 1;
        max-width: 300px;
    }

    .param-in, .param-out {
        margin-top: 16px;
    }

    .param-in, .param-out, .operations {
        .title {
            border-left: 2px solid @primary-color;
            padding-left: 4px;
            line-height: 1;
        }
    }

    .ant-form-item {
        margin-bottom: 12px;
    }

    .ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        cursor: initial;
        color: @text-color;
    }
    .ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector input {
        cursor: initial;

    }
}
@primary-color: #0168B3;@text-color: #222a53;
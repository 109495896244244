.site-manage-container {
    .page-header {
        display: flex;
        margin-bottom: 10px;
    }

    .upload-file {
        margin-bottom: 10px;
    }

    .main {
        padding: 10px;
        background-color: white;
        padding-bottom: 20px;

    }

    .ant-table-thead>tr>th {
        padding: 8px 16px;
        background: #eef1f6;
        // border: 1px solid #cecdcd;
    }

    .ant-table-tbody>tr>td {
        padding: 6px 16px;
    }

    .editable-cell {
        position: relative;
    }

    .editable-cell-value-wrap {
        padding: 5px 12px;
        min-height: 32px;
        cursor: pointer;

        &:hover {
            border: 1px solid #d9d9d9;
            border-radius: 4px;
            padding: 4px 11px;
        }
    }
}
@primary-color: #0168B3;@text-color: #222a53;
.dashboard-card.device {
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: -6px;
        >.title {
            border-left: 3px solid @text-color;
            padding-left: 9px;
            font-size: 16px;
            margin-left: 2px;
            font-weight: bold;
        }
    }
}
@primary-color: #0168B3;@text-color: #222a53;
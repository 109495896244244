.user-setting-container {
    background-color: white;
    padding: 20px;
    min-height: calc(~'100vh - 70px');
    .header {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        .title {
            border-left: 2px solid @primary-color;
            line-height: 1.2;
            padding-left: 4px;
            font-size: 16px;
        }
    }

    .ant-form-item {
        margin-bottom: 0;
    }
}
@primary-color: #0168B3;@text-color: #222a53;
.dashboard-evaluate-container {
    .dashboard-evaluate-card {
        background-color: white;
        // margin-top: 10px;
        box-shadow:0 2px 4px 0 rgba(220,220,220,0.50);
        justify-content: stretch;

        &.main {
            padding: 0 30px;
        }
        &.sensor {
            height: 100%;
            padding: 10px;
            background-color: white;

            .header {
                border-bottom: 1px solid #eaeaea;
                padding-bottom: 10px;
                .title {
                    border-left: 3px solid @text-color;
                    padding-left: 9px;
                    font-size: 16px;
                    margin-left: 2px;
                }
            }

            .content {
                display: block;
                padding: 24px 30px;

                .patterns {
                    display: flex;
                    justify-content: space-around;
                }

                .pattern-item {
                    &.pattern-item-horizon {
                        display: flex;
                    }
                }

                .pattern-name {
                    font-weight: 600;
                }
            }
        }
    }

    .can-click {
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
}
@primary-color: #0168B3;@text-color: #222a53;
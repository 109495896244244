
.device-exception-container {
    background-color: white;
    height: 100%;
    padding: 12px 4px 0;
    .title {
        border-left: 2px solid @text-color;
        padding-left: 8px;
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 12px;
    }
    .content {
        padding: 0 12px;
    }
    .exception-item {
        display: flex;
        justify-content: space-between;
        line-height: 32px;
    }
}
@primary-color: #0168B3;@text-color: #222a53;
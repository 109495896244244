
.dashboard-card.project {

    .project-card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // margin-top: -6px;

        .title {
            border-left: 3px solid @text-color;
            padding-left: 9px;
            font-size: 16px;
            margin-left: 2px;
            font-weight: bold;
        }

    }
    .figure-pane {
        margin-top: 8px;

        .figure {
            width: 100%;
            padding-bottom: 56.25%;
            position: relative;
            overflow: hidden;
            background-color: #d8d8d8;
            border: 1px solid #aeaeae;
            >img {
                top: 0;
                left: 0;
                position: absolute;

            }
        }
    }



    .content-pane {
        padding: 4px;
        overflow: hidden;

        .term-title {
            font-weight: 600;
            margin-bottom: 8px;
            font-size: 16px;
        }

        .term-group {
            display: flex;
            >.term {
                margin-right: 24px;
            }
        }

        .term {
            display: flex;
            align-items: flex-start;
            line-height: 24px;
            min-width: 160px;
            margin-bottom: 8px;
            .label {
                // font-weight: 600;
                margin-right: 4px;
                min-width: 65px;
                &::after {
                    content: ':';
                }
            }
            .detail {
                display: block;
                white-space: pre-wrap;
            }
        }
    }
}
@primary-color: #0168B3;@text-color: #222a53;
.pwd-container {
    background-color: white;
    padding: 10px;
    min-height: calc(~'100vh - 100px');
    .site-page-header {
        margin-bottom: 20px;
    }

    .pwd-steps {
        width: 80%;
        margin: 0 auto;
    }

    .content {
        margin-top: 10px;
        display: flex;
        justify-content: center;

        .pwd-form {
            width: 500px;
        }

        .pwd-btn {
            width: 100%;
        }
    }
}
@primary-color: #0168B3;@text-color: #222a53;

.quantity-display-container {

    .quantity-group-container {
        display: flex;

        .quantity-group-select {
            width: 120px;
            background-color: #eef1f6;
            min-height: 500px;

            .group-tab-item {
                height: 40px;
                line-height: 40px;
                text-align: center;
                border-left: 3px solid transparent;
                transition: all .3s;
                &:not(.button):not(.group-tab-item-active) {
                    cursor: pointer;
                    &:hover {
                        background-color: #f8fafc;
                    }
                }
                &-active {
                    background-color: white;
                    border-left-color: @primary-color;
                }
                &.button {
                    height: 60px;
                    line-height: 60px;
                }
                &.item-hidden {
                    display: none;
                }
                
            }

            .group-tab-item-divider {
                background-color: white;
                margin: 12px 0;
                height: 2px;
            }
        }

        .quantity-group-content {
            flex: 1 1 auto;
            padding: 16px;
            border-bottom: 2px solid #eef1f6;
            border-right: 2px solid #eef1f6;
            border-top: 2px solid #eef1f6;

            .ribbon-row {
                background: #eef1f6;
            }

            .ant-table-thead > tr > th {
                padding: 8px 16px;
                background: #eef1f6;
            }

            .ant-table-tbody > tr > td {
                padding: 6px 16px;
            }

            .pattern {
                display: flex;
                .figure {
                    background-color: #efefef;
                    border-radius: 32px;
                    >img {
                        border-radius: 32px;
                    }
                }

                .pattern-content {
                    // padding-top: 25px;
                    // margin-left: 50px;

                    .response-column {
                        max-width: 320px;
                    }

                    .params-item {
                        display: flex;
                        margin-bottom: 8px;
                        align-items: center;
                        .label {
                            margin-right: 16px;
                            min-width: 25px;
                            &::after {
                                content: ':';
                            }
                        }
                    }
                    .params-edit-btn {
                        margin-left: 12px;
                        display: inline-block;
                        cursor: pointer;
                        &.isEditting {
                            display: none;
                        }
                    }
                    .editting-btn-group {
                        display: none;
                    }
                    .editting-btn-group.isEditting {
                        display: flex;
                    }
                    .quantity-label {
                        margin-left: 6px;
                        color: red;
                    }
                    .pattern-name {
                        font-size: 16px;
                        font-weight: 600;
                    }
                    .title {
                        border-left: 2px solid @text-color;
                        line-height: 1.2;
                        padding-left: 4px;
                        margin: 12px 0 8px;
                    }

                    .ant-input-disabled, .ant-input[disabled] {
                        color: #222a53;
                        cursor: text;
                    }
                }

            }

            .quantity-data-tools {
                display: flex;
                margin: 24px auto;
                align-items: center;
                // justify-content: flex-end;
            }

        }
    }
}

// @media screen and (max-width:1440px) {
//     .pattern-content.quantity-evaluate {
//         display: none;

//         .response-column {
//             display: none;
//         }
//     }
// }
@primary-color: #0168B3;@text-color: #222a53;

.device-manage-history {
    background-color: white;
    height: 100%;
    padding: 12px 4px 0;
    // min-width: 240px;
    .title {
        border-left: 2px solid @text-color;
        padding-left: 8px;
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 12px;
    }
    .manage-timeline {
        padding:0 10px;
    }

    .ant-timeline {
        margin-top: 12px;
        .ant-timeline-item {
            padding-bottom: 16px;
        }
        .ant-timeline-item-last > .ant-timeline-item-content {
            min-height: initial;
        }
    }
}
@primary-color: #0168B3;@text-color: #222a53;

.native-auth-layout {
    display: flex;
    flex-direction: column;
    background: #f0f2f5;
    height: 100%;

    .content {
        margin: 160px auto 0;
        width: 600px;
    }
}
@primary-color: #0168B3;@text-color: #222a53;
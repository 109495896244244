.quantity-limit-all-container {
    // display: flex;
    // justify-content: center;
    background-color: white;
    min-height: 500px;
    padding: 10px;
    margin-bottom: 40px;

    .operations {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
    }

    .quantity-limit-tables {

        .ant-table-thead>tr>th {
            padding: 8px 16px;
            background: #eef1f6;
        }

        .ant-table-tbody>tr>td {
            padding: 2px 16px;
        }

        .editable-cell {
            position: relative;
        }

        .editable-cell-value-wrap {
            padding: 5px 12px;
            cursor: pointer;
            min-height: 32px;

            &:hover {
                border: 1px solid #d9d9d9;
                border-radius: 4px;
                padding: 4px 11px;
            }

            // border: 1px solid #d9d9d9;
            // border-radius: 4px;
            // padding: 4px 11px;
        }

        // .number-input {
        //     text-align: right;
        //     max-width: 130px;
        //     margin: -5px -14px;
        // }
        // .number-label {
        //     min-width: 80px;
        //     text-align: right;
        //     min-height: 22px;
        //     // padding-right: 12px;
        // }

        // .edit-button {
        //     margin: -2px 0;
        // }
    }
}
@primary-color: #0168B3;@text-color: #222a53;
@sensor-working: #009547;
@sensor-abnormal: #ff7100;
@sensor-offline: #666666;
@sensor-blank: #eef1f6;

.button-pane {
    margin: 10px 0;
    .can-drag.normal {
        display: none;
    }
    .save:not(.can-drag) {
        display: none;
    }
}

.dbox-sensor-pane-group {
    display: flex;
    align-items: stretch;
    margin-bottom: 32px;
    &.can-drag {
        .dbox-sensor-pane {
            width: 75%;
        }

        .unlink-sensor-list {
            width: 25%;
            display: block;
        }
    }
}

.unlink-sensor-list {

    width: 0px;
    padding-left: 10px;
    transition: all .3s;
    display: none;

    .title {
        display: flex;
        overflow: hidden;
        height: 48px;
        align-items: center;
        background-color: white;
    }

    .list-pane {
        // height: 100%;
        width: 100%;
        background-color: white;
        max-height: 650px;
        overflow: auto;

    }

    .nochannel-sensor-item {
        display: flex;
        justify-content: space-between;
        height: 40px;
        border-bottom: 1px solid #eaeaea;
        overflow: hidden;
        align-items: center;
        padding: 0 12px 0 20px;
        cursor: move;
    }
}

.dbox-sensor-pane {
    background-color: white;
    min-height: 300px;
    padding: 16px;
    width: 100%;
    transition: all .3s;

    .sensor-item-list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        .dbox-sensor-item {
            width: 200px;
            display: flex;
            align-items: center;
            margin-bottom: 12px;
            margin-right: 24px;
            position: relative;
            .channel-title {
                font-size: 20px;
                font-weight: 600;
                display: block;
                min-width: 32px;
                text-align: right;
                margin-right: 8px;
            }
            .release-icon {
                position: absolute;
                right: -15px;
                bottom: 50%;
                transform: translateY(50%);
                font-size: 18px;
                color: #ff4d4f;
                cursor: pointer;
                display: none;
            }
            .release-icon.can-drag {
                display: block;
            }
            .channel-box {
                border: 1px solid #666666;
                width: 150px;
                border-radius: 4px;
                height: 65px;
                align-items: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                position: relative;
                .hover-display {
                    display: none;
                }
                &:hover .hover-display {
                    display: block;
                }
                .sensor-status-tag {
                    border-radius: 200px;
                    width: 10px;
                    height: 10px;
                    position: absolute;
                    right: 8px;
                    bottom: 8px;
                }

                &.sensor-working {
                    border-color: @sensor-working;
                    >div {
                        color: @sensor-working;
                    }
                    .sensor-status-tag {
                        background-color: @sensor-working;
                    }
                }
                &.sensor-offline {
                    border-color: @sensor-offline;
                    >div {
                        color: @sensor-offline;
                    }
                    .sensor-status-tag {
                        background-color: @sensor-offline;
                    }
                }
                &.sensor-abnormal {
                    border-color: @sensor-abnormal;
                    >div {
                        color: @sensor-abnormal;
                    }
                    .sensor-status-tag {
                        background-color: @sensor-abnormal;
                    }
                }
                &.can-drag:not(.sensor-blank) {
                    cursor: move;
                    box-shadow: 0 2px 6px 2px rgba(167,167,167,0.50);
                } 
                &:not(.can-drag):not(.sensor-blank) {
                    cursor: pointer;
                    &:hover {
                        background-color: #fafbfd;
                    }
                    &:active {
                        background-color: #eef1f6;
                    }
                }
                &.will-drop:not(.sensor-blank) {
                    background-color: #eaeaea;
                }


                &.sensor-blank {
                    border-color: @sensor-blank;
                    background-color: @sensor-blank;
                }
                &.sensor-blank.will-drop {
                    border: 2px dashed #009547;
                }
            }
        }
    }
}


@primary-color: #0168B3;@text-color: #222a53;
@panel-color: @primary-color;

.mobject-container {
    display: flex;
    margin-bottom: 16px;
    // justify-content: center;
    align-items: stretch;
    .mobject-select-pane {
        width: 120px;
        background-color: white;
        margin-right: 10px;
        padding-top: 10px;
        min-height: 700px;
        height: 100%;

        &.mobject-select-blank {
            width: 400px;
        }
        >.title {
            border-left: 3px solid @text-color;
            padding-left: 9px;
            font-size: 14px;
            margin-left: 2px;
            margin-bottom: 4px;
        }
        >.search-input {
            margin-bottom: 4px;
        }
        .mobject-select-list {
            max-height: 700px;
            overflow: auto;
        }
        .mobject-select-item {
            height: 30px;
            text-align: center;
            line-height: 30px;
            cursor: pointer;
            border: 3px solid transparent;
            // border-right: 3px solid transparent;
            &:hover {
                background-color: #f8fafc;
            }
            &-active {
                color: @panel-color;
                border: 2px solid @panel-color;
                background-color: #f8fafc;
            }
        }
    }

    .mobject-tabs {
        background-color: white;
        flex: 1 1 auto;
        padding: 0 16px;
        padding-bottom: 16px;
    }

    .mobject-list-horizon-container {
        margin-bottom: 2px;
        padding: 0 6px;
        background-color: white;
        // .ant-tabs {
        //     padding: 0 6px;
        //     background-color: white;
        // }
        .ant-tabs-bar {
            margin-bottom: 0;
        }
    }
}

@media screen and (max-width:1440px) {
    .mobject-select.large {
        display: none;
    }
    .mobject-select.small {
        display: block;
    }
    .mobject-container {
        display: block;
    }
}

@media screen and (min-width:1440px) {
    .mobject-select.large {
        display: block;
    }
    .mobject-select.small {
        display: none;
    }
}
@primary-color: #0168B3;@text-color: #222a53;
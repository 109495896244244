
.constage-detail-container {
    // display: flex;
    margin-bottom: 20px;
    .constage-info {
        background-color: white;
        padding: 10px;
        min-height: 500px;
        margin-bottom: 10px;
        height: calc(100% - 10px);

        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .title {
                font-size: 16px;
                font-weight: 600;
                padding-left: 8px;
                border-left: 2px solid @text-color;
                line-height: 1;
                display: flex;
                >div {
                    margin-right: 8px;
                }
            }
        }
        .main {
            margin-top: 10px;
            display: flex;
        }

        .figure {
            width: 100%;
            padding-bottom: 56.25%;
            position: relative;
            overflow: hidden;
            background-color: #d8d8d8;
            border: 1px solid #d8d8d8;
            border-radius: 8px;
    
            >img {
                top: 0;
                left: 0;
                position: absolute;
            }
        }

        .footer {
            margin-top: 10px;
            padding: 0 40px;
        }
    }
}
@primary-color: #0168B3;@text-color: #222a53;

.report-container {

    .page-header {
        // display: flex;
        margin-bottom: 10px;
        padding: 16px;
        background-color: white;

        .report-btn {
            width: 100%;
            height: 70px;
            font-weight: bold;
            font-size: 22px;
            color: @primary-color;
            background-color: #f8fafc;
            box-shadow:0 1px 4px 2px rgba(214,214,214,0.20);

        }

        .report-btn:not(:hover) {
            border: 1px solid #eaeaea;
        }
    }

    .reports-generating {
        background-color: white;
        margin-bottom: 10px;
        padding: 10px 20px;

        .request-report-item {
            >span {
                margin-right: 10px;
                font-weight: bold;
            }
        }
    }

    .content {
        min-height: calc(~'100vh - 240px');
        background-color: white;
        padding: 10px 20px;

        .operations {
            margin-bottom: 10px;
        }

        .ant-table-thead > tr > th {
            padding: 8px 16px;
            background: #eef1f6;
        }

        .ant-table-tbody > tr > td {
            padding: 8px 16px;
        }

        .can-click {
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}
@primary-color: #0168B3;@text-color: #222a53;
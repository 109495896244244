@t5-color: #e45331;
@t4-color: #f68240;
@t3-color: #efa829;
@button-color: #222a53;
@button-hover: #384479;

.dashboard-device-card{
    padding: 6px 30px;
    padding-left: 30px;
    // width: 100px;
    // height: 28px;
    .extra-button-group-main{
    border-bottom:1px solid #cde1e9;
    padding-left: 20px;
    padding-top: 16px;
    padding-bottom: 13px;
    font-weight: 550;
    letter-spacing:0;
    font-size: 20px;
    }

    .extra-button-group {
        float: right;
        padding: 0px 0px;
        .ant-btn-primary {
            background-color: @button-color;
            border-color: @button-color;
            border-radius:4px;
            // width:100px;
            // height:30px;
            // font-size:16px;
            color:#ffffff;
            text-align: center;
            font-size: 16px;
            &:hover {
                background-color: @button-hover;
                border-color: @button-hover;
            }
        }
    }
    .extra-button-groupdbox{
        padding-left:8px;
        padding-top: 15px;
        // overflow: auto;
        // scroll-behavior: auto;
        // scrollbar-3dlight-color: #e45331; 
        ::-webkit-scrollbar{
            height: 98px !important;
                width: 28px !important;
        }
        
        ::-webkit-scrollbar-thumb {
            border-radius: 0;
                    border-style: dashed;
                    background-color: rgba(12, 80, 235, 0.4);
                    border-color: #e2242400;
                    border-width: 1.5px;
                    background-clip: padding-box;
  } 

        .active{
            background-color: #222a53 !important;
            color: #ffffff !important;
            box-shadow: 0 2px 4px 0 rgba(198,195,195,0.50);
            width:119px;height:40px;
        }
        .unactive{
            background-color: #eef1f6 !important;
            box-shadow:0 2px 3px 0 rgb(219,218,218,0.50);
            width:118px;height:40px;
        }
        .ant-btn-primary{
            background-color: #384479;
            border-color: #eef1f6;
            font-size: 16px;
            color: #222a53;  
            border-radius: 4px;
            // font-weight: 500;
            letter-spacing:0;
            // text-align:left;                                                                                                                                                                                                                                                                                                                                                                                                                                            
        }
    }
.device-content {
    // display: flex;
    padding: 8px 0px;
        .device-table {
        width: 100%;
        .ribbon-row {
            background: #eef1f6;
        }

        .ant-table-thead > tr > th {
            padding: 8px 16px;
            background: #eef1f6;
        }
        .ant-table-tbody > tr > td {
            padding: 6px 16px;
        }
        
    }
}
}
@primary-color: #0168B3;@text-color: #222a53;

.sensor-dbox-select-panel {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .header {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .title {
            margin-bottom: 0;
            margin-right: 8px;
            line-height: 24px;
        }
    }

    .operations {
        padding: 0 5%;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
    }

    .dbox-select {
        text-align: center;
        padding: 0 5%;
        &-com {
            width: 100%;
        }
        .dbox-btn {
            background-color: #e9f4fc;
        }

        .term {
            display: flex;
            align-items: center;
            min-width: 160px;
            // margin-bottom: 12px;
            .detail {
                display: block;
                white-space: pre-wrap;
            }
        }
    }

    .dbox-channel-select {
        width: 100%;
        flex: 1;

        .channel-group {
            display: flex;
            width: 90%;
            // height: calc(100% - 10px);
            flex-wrap: wrap;
            margin: 10px auto;
            border: 1px dashed #d8d8d8;
            padding: 8px;
            .channel-cell {
                background-color: #f8fafc;
                color: #b8b8b8;
                position: relative;
                height: 0px;
                padding-bottom: 75%;
                &-odd {
                    background-color: #eef1f6;
                }
                &-active {
                    background-color: @primary-color;
                    color: white;
                }
                &-select-enabled:not(.channel-cell-active) {
                    cursor: pointer;
                    &:hover {
                        background-color: #87c2ec;
                        color: white;
                    }
                    &:active {
                        background-color: @primary-color;
                        color: white;
                    }
                }
                &-select-disabled {
                    cursor: not-allowed;
                }
                &-can-go {
                    cursor: pointer;
                    &:hover {
                        background-color: #e3f2fd;
                    }
                }
                .content {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: bold;
                }
                .content-online {
                    color: #009547;
                }
                // .content-offline {
                //     color: #009547;
                // }
                .content-abnormal {
                    color: #f68240;
                }
            }
        }

        .channel-blank {
            width: 100%;
        }
    }

    
}
@primary-color: #0168B3;@text-color: #222a53;
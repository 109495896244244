

.sensor-detail-container {
    padding-bottom: 32px;
    .card {
        background-color: white;
        height: 300px;
        padding: 12px 16px 0;
        .title {
            border-left: 2px solid @text-color;
            color: @text-color;

            padding-left: 8px;
            font-size: 16px;
            line-height: 21px;
            margin-bottom: 12px;
        }
        .edit-header {
            display: flex;
            align-items: center;
            margin-bottom: 12px;
            & .title {
                margin-bottom: 0;
            }
        }

        .term {
            display: flex;
            align-items: flex-start;
            line-height: 28px;
            min-width: 160px;
            margin-bottom: 8px;
            .label {
                // font-weight: 600;
                margin-right: 20px;
                text-align: right;
                min-width: 65px;
                &::after {
                    content: ':';
                }
            }
            .detail {
                display: block;
                white-space: pre-wrap;
            }
        }
    }

    .sensor-work-radio.ant-radio-group-solid  {
        .ant-radio-button-wrapper:nth-of-type(1).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
            background: #B3B5B9;
            border-color: #B3B5B9;
        }
    }
    .sensor-work-radio.ant-radio-group-solid  {
        .ant-radio-button-wrapper:nth-of-type(2).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
            background: #009547;
            border-color: #009547;
            &::before {
                background-color: #009547;
            }
        }
    }
    .sensor-work-radio.ant-radio-group-solid  {
        .ant-radio-button-wrapper:nth-of-type(3).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
            background: #f68240;
            border-color: #f68240;
            &::before {
                background-color: #f68240;
            }
        }
    }

    .operations {
        .operation-item {
            margin-bottom: 24px;
        }
    }

    .sensor-link-pane {
        padding: 12px;
        background-color: white;
        min-height: 350px;
        .title {
            padding-left: 6px;
            border-left: 2px solid @text-color;
            line-height: 1.2;
            margin-bottom: 10px;
            font-size: 16px;
            font-weight: bold;
            text-align: left;
        }

        .params-pane {
            .quantity-list {
                // display: flex;
                // flex-direction: column;
                // flex-wrap: wrap;
                // align-items: flex-start;
            }
            .quantity-item {
                padding: 4px 16px;
                border: 1px solid @primary-color;
                background-color: rgb(230, 243, 252);
                color: @primary-color;
                border-radius: 4px;
                // line-height: 1.6;
                text-align: center;
                height: 32px;
                cursor: pointer;
                user-select: none;
                margin-bottom: 10px;
                margin-right: 10px;
                &:active {
                    background-color: #cae6fa;
                }
                >span {
                    line-height: 24px;
                }
            }
        }
    }
    
    .figure-pane {
        width: 100%;
        height: 100%;
        height: 300px;
        // text-align: center;
        
    }

    .link-tooltip-pane {
        background-color: white;
        box-shadow:inset 0 1px 3px 0 rgba(209,209,209,0.50);
        border-radius: 20px;
        height: 100px;
    }

    .link-pane {
        font-size: 48px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .unlink {
            color: #ff4d4f;
        }
        .link {
            color: #2ea063;
            transform: rotate(45deg);
        }
        .link-footer {
            font-size: 20px;
        }
    }

    .can-click {
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }

    .data-display-pane {
        background-color: white;
        min-height: 500px;
        padding: 10px;
    }
}
@primary-color: #0168B3;@text-color: #222a53;
.global-enterprise-container {

    .page-header {
        display: flex;
        background-color: white;
        padding: 10px 24px;
        align-items: center;
        .title {
            // font-weight: 600;
            font-size: 36px;
            line-height: 1.5;
            margin-left: 20px;
            // margin: 0 12px;
            // color: rgba(0, 0, 0, 0.85);
        }
        
        .ant-upload-picture-card-wrapper {
            width: inherit;
        }
    }
    .avatar-uploader>.ant-upload {
        width: 200px;
        height: 100px;
    }

    .user-main {
        margin-top: 10px;
        background-color: white;
        padding: 10px;

        padding-bottom: 20px;
        .ant-table-thead>tr>th {
            padding: 8px 16px;
            background: #eef1f6;
        }

        .ant-table-tbody>tr>td {
            padding: 6px 16px;
        }

        .editable-cell {
            position: relative;
          }
          
          .editable-cell-value-wrap {
            padding: 5px 12px;
            cursor: pointer;
            min-height: 32px;
            &:hover {
                border: 1px solid #d9d9d9;
                border-radius: 4px;
                padding: 4px 11px;
            }
          }
    }

    .user-main-header {
        display: flex;
        margin-bottom: 10px;
    }
}
@primary-color: #0168B3;@text-color: #222a53;
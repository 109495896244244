.drawing-edit-container {
    background-color: white;
    padding: 10px;
    .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .upload-file {
        margin-bottom: 10px;
    }
    .ant-table-thead>tr>th {
        padding: 8px 16px;
        background: #eef1f6;
    }

    .ant-table-tbody>tr>td {
        padding: 6px 16px;
    }

    .editable-cell {
        position: relative;
      }
      
      .editable-cell-value-wrap {
        padding: 5px 12px;
        cursor: pointer;
        min-height: 32px;
        &:hover {
            border: 1px solid #d9d9d9;
            border-radius: 4px;
            padding: 4px 11px;
        }
      }
}
@primary-color: #0168B3;@text-color: #222a53;
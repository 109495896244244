.sensor-list-page {
    background: white;
    min-height: 600px;
    padding-bottom: 20px;
    .left {
        .ribbon-row {
            background: #eef1f6;
        }

        .ant-table-thead > tr > th {
            padding: 8px 16px;
            background: #eef1f6;
        }

        .ant-table-tbody > tr > td {
            padding: 8px 16px;
        }

        .click-row {
            cursor: pointer;
        }

        .header-tools {
            display: flex;
            padding: 12px 16px;

            .search-input {
                max-width: 230px;
            }
        }

        .list-content {
            padding: 0 16px;
        }

        .editable-cell {
            position: relative;
          }
          
          .editable-cell-value-wrap {
            padding: 5px 12px;
            cursor: pointer;
            min-height: 32px;
            &:hover {
                border: 1px solid #d9d9d9;
                border-radius: 4px;
                padding: 4px 11px;
            }
          }
    }
    .right {
        padding: 56px 12px;
    }
}
@primary-color: #0168B3;@text-color: #222a53;
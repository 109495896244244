.section-page-container {
    background-color: white;
    min-height: 500px;
    padding: 10px 20px 30px;

    .header {
        text-align: right;
        margin-bottom: 10px;
    }

    .ant-table-thead>tr>th {
        padding: 8px 16px;
        background: #eef1f6;
    }

    .ant-table-tbody>tr>td {
        padding: 6px 16px;
    }

    .select-row {
        cursor: pointer;
    }

}
@primary-color: #0168B3;@text-color: #222a53;
.forget-pwd-container {
    width: 350px;

    .submit-group {
        display: flex;
        justify-content: space-between;
    }

    .submit-button {
        min-width: 100px;
    }
}
@primary-color: #0168B3;@text-color: #222a53;
.quantity-step-container {
    .steps-content {
        margin-top: 16px;
        // width: 100%;

        .quantity-step-item {
            &.step-one {
                margin-top: 48px;
                background-color: #f8fafc;
                padding: 30px;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                // margin: 48px auto;
                height: 100%;
            }
            .quantity-group-content {
                height: 100%;
                margin-left: 32px;
                .title {
                    font-size: 16px;
                    font-weight: 600;
                    &::after {
                        content: ':';
                    }
                }
                .group-list {
                    margin-bottom: 24px;
                }
            }
            &.step-two {
                margin-top: 12px;
                padding: 0 30px;
                padding-bottom: 32px;
                .quantity-group-title {
                    font-size: 16px;
                    line-height: 1.2;
                    margin-bottom: 4px;
                    font-weight: 600;
                }
                .quantity-group-item {
                    background-color: #f2f5f8;
                    min-height: 250px;
                    margin-bottom: 12px;
                    padding: 8px;
                    display: flex;
                    .pattern-figure-select {
                        display: flex;
                        align-items: center;
                        .change-figure-button {
                            margin: 0 16px;
                            height: 100%;
                            &:hover {
                                background-color: #f5f6f8;
                            }
                            &:active {
                                background-color: white;
                            }
                        }
                        .figure {
                            background-color: #eaeaea;
                            border-radius: 32px;
                            >img {
                                border-radius: 32px;
                            }
                        }
                    }
                    .pattern-content {
                        min-width: 150px;
                        margin-left: 12px;
                        .chinese-name {
                            // text-align: center;
                            font-size: 16px;
                            font-weight: 600;
                        }
                        .quantity-label {
                            margin-left: 6px;
                            color: red;
                        }

                    }
                    .pattern-params {
                        padding-top: 25px;
                        margin-left: 100px;
                        .params-item {
                            display: flex;
                            margin-bottom: 8px;
                            align-items: center;
                            .label {
                                margin-right: 6px;
                                min-width: 34px;
                                &::after {
                                    content: ':';
                                }
                            }
                        }
                        .quantity-label {
                            margin-left: 6px;
                            color: red;
                        }
                    }
                    .pattern-quantity-title {
                        border-left: 2px solid @text-color;
                        line-height: 1.2;
                        padding-left: 4px;
                        margin: 12px 0 8px;
                    }
                }
            }

            &.step-three {
                // margin-top: 48px;
                // background-color: #f8fafc;
                padding: 16px 30px;
                min-height: 200px;
                // display: flex;
                // justify-content: center;

                .operations {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 8px;
                }

                .ant-table-thead > tr > th {
                    padding: 8px 16px;
                    background: #eef1f6;
                }
    
                .ant-table-tbody > tr > td {
                    padding: 6px 16px;
                }

                .editable-cell {
                    position: relative;
                  }
                  
                  .editable-cell-value-wrap {
                    padding: 5px 12px;
                    cursor: pointer;
                    min-height: 32px;
                    &:hover {
                        border: 1px solid #d9d9d9;
                        border-radius: 4px;
                        padding: 4px 11px;
                    }
                    border: 1px solid #d9d9d9;
                    border-radius: 4px;
                    padding: 4px 11px;
                  }

                // .quantity-tables {
                //     // display: flex;
                //     // justify-content: center;

                //     .quantity-name {
                //         min-width: 150px;
                //     }
                //     .initial-value {
                //         margin-right: 16px;
                //     }
                //     .ant-table-thead > tr > th {
                //         padding: 8px 16px;
                //         background: #eef1f6;
                //     }
        
                //     .ant-table-tbody > tr > td {
                //         padding: 6px 16px;
                //         // min-height: 35px;
                //         &.editable-cell {
                //             padding: 1px 2px;
                //         }
                //     }

                //     .number-input {
                //         text-align: right;
                //         max-width: 130px;
                //     }

                // }
            }
        }
        .steps-action {
            margin-top: 24px;
            display: flex;
            justify-content: center;
        }
    }
}

@media screen and (max-width:1440px) {
    .quantity-step-header {
        width: 80%;
        margin: 8px auto;
    }
}

@media screen and (min-width:1440px) {
    .quantity-step-header {
        width: 800px;
        margin: 16px auto;
    }
}
@primary-color: #0168B3;@text-color: #222a53;
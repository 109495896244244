.setting-client-container {
    padding: 10px 20px;
    background-color: white;
    min-height: 700px;

    .client-setting-user-add {
        background-color: white;
        // padding: 10px 0;
    }



    .operations {
        margin-bottom: 10px;
    }

    .page-header {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
    }

    .main {
        padding-bottom: 20px;
        .ant-table-thead>tr>th {
            padding: 8px 16px;
            background: #eef1f6;
        }

        .ant-table-tbody>tr>td {
            padding: 6px 16px;
        }

        .editable-cell {
            position: relative;
          }
          
          .editable-cell-value-wrap {
            padding: 5px 12px;
            cursor: pointer;
            min-height: 32px;
            &:hover {
                border: 1px solid #d9d9d9;
                border-radius: 4px;
                padding: 4px 11px;
            }
          }
        // .client-table {}
    }
}

.client-setting-user-import {
    .ant-table-thead>tr>th {
        padding: 8px 16px;
        background: #eef1f6;
    }

    .ant-table-tbody>tr>td {
        padding: 6px 16px;
    }
}
@primary-color: #0168B3;@text-color: #222a53;
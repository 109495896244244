.project-info-edit-container {
    background-color: white;
    padding: 30px 40px 50px;

    margin-bottom: 40px;

    .project-form-group {
        display: flex;
        justify-content: space-between;
    }

    .project-form {
        flex: 1 1 auto;
        max-width: 1200px;

        .table-top-operations {
            text-align: right;
        }
    }

    .table-pane {
        display: flex;
        margin-bottom: 24px;

        >.title {
            width: 12.5%;
            text-align: end;

            &::after {
                content: ':';
                margin: 0 8px 0 2px;
            }
        }
    }

    .info-table-edit {
        .ant-table-thead>tr>th {
            padding: 8px 16px;
            background: #eef1f6;
        }

        .ant-table-tbody>tr>td {
            padding: 6px 16px;

            // min-height: 35px;
            &.editable-cell.isEditting {
                padding: 1px 2px;
            }
        }
    }
}


.project-info-board {
    background-color: white;
    padding: 16px;
    margin-bottom: 10px;
    min-height: 150px;
    height: calc(~'100% - 10px');
    border-radius: 4px;
    box-shadow: 0 0 2px 2px rgba(234,234,234,0.50);
    .header {
        border-bottom: 1px solid #cde1e9;
        margin-bottom: 12px;
        padding-bottom: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        >.title {
            border-left: 2px solid @primary-color;
            line-height: 1.2;
            padding-left: 4px;
            font-size: 16px;
        }
    }
    .content {
        max-height: 280px;
        overflow: auto;

        .info-wrap {
            display:block;
            white-space:pre-wrap;
            word-wrap : break-word;
        }
    }

    .figure {
        width: 100%;
        padding-bottom: 56.25%;
        position: relative;
        overflow: hidden;
        background-color: #d8d8d8;
        border: 1px solid #aeaeae;
        border-radius: 8px;


        >img {
            top: 0;
            left: 0;
            position: absolute;
        }
    }

    .info-item {
        display: flex;
        margin: 12px 0;
        font-size: 16px;
        >.label {
            margin-right: 20px;
            min-width: 70px;
            font-weight: 600;
            &::after {
                content: ':';
            }
        }
    }

    .info-table-display {
        .ant-table-thead>tr>th {
            padding: 8px 16px;
            background: #eef1f6;
        }

        .ant-table-tbody>tr>td {
            padding: 6px 16px;
        }

        .ribbon-row {
            background: #eef1f6;
        }
    }
}
@primary-color: #0168B3;@text-color: #222a53;
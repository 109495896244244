
.project-list-container {
    
    .page-header {
        text-align: right;
    }

    .main {
        margin-top: 16px;
        // padding: 30px;
        // background-color: white;
    }

    .project-list-panel {
        background-color: white;
        padding: 20px;

        .project-list-row {
            cursor: pointer;
        }
        .ant-table-thead > tr > th {
            padding: 8px 16px;
            background: #eef1f6;
        }

        .ant-table-tbody > tr > td {
            padding: 8px 16px;
        }
    }

    .project-card-panel {
        .project-card {
            background-color: white;
            padding: 20px 10px;
            margin-bottom: 20px;
            .title {
                height: 48px;
                line-height: 24px;
                font-size: 16px;
                overflow: hidden;
                word-break: break-all;
                margin-bottom: 8px;
            }
            .info {
                display: flex;
                justify-content: space-between;
                margin-top: 8px;
            }

            .divider {
                margin: 12px 0;
            }

            .enter-btn {
                width: 100%;
                background-color: #d8eef5;
                border-color: #d8eef5;
                color: @primary-color;
                font-weight: 600;
                border-radius: 4px;
                &:hover {
                    color: white;
                    background-color: @primary-color;
                }
                &:active {
                    background-color: #488ec0;
                }
            }
        }
    }
}
@primary-color: #0168B3;@text-color: #222a53;
.project-standard-container {
    min-height: calc(~'100vh - 100px');

    .standard-card {
        background-color: white;
        min-height: 700px;
        padding: 20px;
        // align-items: stretch;
        height: 100%;
        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #cde1e9;
            margin-bottom: 12px;
            padding-bottom: 6px;
            min-height: 40px;
            .title {
                border-left: 2px solid @text-color;
                font-size: 16px;
                padding-left: 4px;
                line-height: 1.2;
            }

       
        }
    }
}
@primary-color: #0168B3;@text-color: #222a53;
.quantity-detail-limit-pane {
    // display: flex;
    // justify-content: center;

    .operations {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
    }

    .editable-cell {
        position: relative;
      }
      
      .editable-cell-value-wrap {
        padding: 5px 12px;
        cursor: pointer;
        min-height: 32px;
        &:hover {
            border: 1px solid #d9d9d9;
            border-radius: 4px;
            padding: 4px 11px;
        }
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        padding: 4px 11px;
      }

    // .quantity-limit-tables {
    //     display: flex;
    //     // justify-content: center;

    //     .ant-input-disabled, .ant-input[disabled] {
    //         color: inherit;
    //         cursor: text;
    //     }

    //     .quantity-name {
    //         min-width: 150px;
    //     }
    //     .initial-value {
    //         margin-right: 8px;
    //     }
    //     .ant-table-thead > tr > th {
    //         padding: 8px 16px;
    //         background: #eef1f6;
    //     }

    //     .ant-table-tbody > tr > td {
    //         padding: 6px 16px;
    //         // min-height: 35px;
    //         &.editable-cell.isEditting {
    //             padding: 1px 2px;
    //         }
    //     }

    //     .number-input {
    //         text-align: right;
    //         max-width: 130px;
    //     }
    //     .number-label {
    //         min-width: 80px;
    //         text-align: right;
    //         min-height: 22px;
    //         // padding-right: 12px;
    //     }

    // }
}
@primary-color: #0168B3;@text-color: #222a53;
.global-standard-container {

    .header {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .title {
            padding-left: 4px;
            border-left: 2px solid @text-color;
            font-size: 16px;
            font-weight: bold;
        }
    }

    .procedure-list {
        // background-color: white;
        // padding: 10px;
        .title {
            padding-left: 4px;
            border-left: 2px solid @text-color;
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 10px;
        }
    }

    .procecure-pane {
        background-color: white;
        padding: 10px;
        min-height: 290px;
        // border: 1px solid #d9d9d9;

        .site-tag-plus {
            background: #fff;
            border-style: dashed;
          }
          .edit-tag {
            user-select: none;
          }
          .tag-input {
            width: 78px;
            margin-right: 8px;
            vertical-align: top;
          }
    }
    .standard-list {
        background-color: white;
        padding: 10px 10px 20px;
    }

    .ant-table-thead>tr>th {
        padding: 8px 16px;
        background: #eef1f6;
    }

    .ant-table-tbody>tr>td {
        padding: 6px 16px;
    }

    .editable-cell {
        position: relative;
    }

    .editable-cell-value-wrap {
        padding: 5px 12px;
        cursor: pointer;

        &:hover {
            border: 1px solid #d9d9d9;
            border-radius: 4px;
            padding: 4px 11px;
        }
    }

    // .display-cell-wrap {
    //     // padding: 5px 12px;
    // }
}


@primary-color: #0168B3;@text-color: #222a53;

.dbox-info-pane {
    margin-left: 16px;
    padding-top: 12px;
    .title {
        border-left: 2px solid @text-color;
        padding-left: 8px;
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 12px;
    }
    .term {
        display: flex;
        align-items: flex-start;
        line-height: 28px;
        min-width: 160px;
        margin-bottom: 8px;
        .label {
            // font-weight: 600;
            margin-right: 20px;
            text-align: right;
            min-width: 65px;
            &::after {
                content: ':';
            }
        }
        .detail {
            display: block;
            white-space: pre-wrap;
        }
    }
}
@primary-color: #0168B3;@text-color: #222a53;

.device-statistics {

    .ant-table table {
        border: 1px solid #f0f0f0;
        box-shadow: 2px 4px 4px 0 #eaeaea;
    }

    .ant-table-thead > tr > th {
        padding: 8px 16px;
        background: white;
    }

    .ant-table-tbody > tr > td {
        padding: 6px 16px;
        border-bottom: none;
    }
}
@primary-color: #0168B3;@text-color: #222a53;
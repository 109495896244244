
.quantity-link-container {
    padding: 10px 10px 20px;
    background-color: white;
    min-height: 700px;
    .page-header {
        display: flex;
        margin-bottom: 10px;

        .ant-input-search {
            width: 250px;
        }
    }

    .ant-table-thead > tr > th {
        padding: 8px 16px;
        background: #eef1f6;
    }

    .ant-table-tbody > tr > td {
        padding: 2px 16px;
    }

    .editable-cell {
        position: relative;
      }
      
      .editable-cell-value-wrap {
        padding: 5px 12px;
        min-height: 32px;
        cursor: pointer;
        &:hover {
            border: 1px solid #d9d9d9;
            border-radius: 4px;
            padding: 4px 11px;
        }
      }
}
@primary-color: #0168B3;@text-color: #222a53;

.main-header {
    height: 50px;
    background: #fff;
    display: flex;

    .title {
        padding-left: 25px;
        align-items: center;

        >span {
            line-height: 50px;
            font-family: PingFangHK-Medium;
            font-size: 20px;
            color: #222a53;
            display: block;
        }
    }

    .right {
        display: flex;
        align-items: center;
        margin-right: 24px;
        .action {
            display: flex;
            align-items: center;
            height: 100%;
            padding: 0 12px;
            cursor: pointer;
            transition: all 0.3s;
            // > span {
            //   color: @text-color;
            //   vertical-align: middle;
            // }
            &:hover {
              background: rgba(0, 0, 0, 0.025);
            }
            // &:global(.opened) {
            //   background: @pro-header-hover-bg;
            // }
            &.account {
                .avatar {
                    margin-right: 8px;
                }
                .name {
                    // line-height: 50px;
                    color: @text-color;
                    font-size: 16px;
                }
            }
            
            &.primary {
                color: @primary-color;
                font-size: 16px;
                .avatar {
                    margin: 17px 8px 17px 0;
                }
                .name {
                    line-height: 50px;
                }
            }

            &.project-start-btn {
                color: #41aa0c;
                font-size: 22px;
                >.content {
                    font-size: 16px;
                    margin-left: 4px;
                }
            }

            &.project-stop-btn {
                color: #ff4d4f;
                font-size: 22px;
                >.content {
                    font-size: 16px;
                    margin-left: 4px;
                }
            }
        }
    }

}

.setting-menu {
    .anticon {
      margin-right: 8px;
    }
    .ant-dropdown-menu-item {
      min-width: 140px;
      min-height: 28px;
      line-height: 28px;
    }
  }
@primary-color: #0168B3;@text-color: #222a53;

.formula-pane {
    padding: 0 10px;

    .ant-form-item {
        margin-bottom: 10px;
    }

    .params-pane {
        margin-top: 14px;

        .ant-input-disabled, .ant-input[disabled] {
            color: initial;
            cursor: text;
        }

        .ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
            cursor: inherit;
            // background-color: white;
            color: @text-color;
        }
    }

    .term {
        display: flex;
        align-items: flex-start;
        line-height: 28px;
        min-width: 160px;
        margin-bottom: 8px;
        .label {
            // font-weight: 600;
            margin-right: 20px;
            text-align: right;
            min-width: 65px;
            &::after {
                content: ':';
            }
        }
        .detail {
            display: block;
            white-space: pre-wrap;
        }
    }
}
@primary-color: #0168B3;@text-color: #222a53;
.drawing-display-container {
    padding-bottom: 32px;
    .drawing-figure {
        padding: 10px;
        background-color: white;

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .title {
                font-size: 16px;
                font-weight: 600;
                >span {
                    display: inline-block;
                    margin-left: 10px;
                }
            }
        }

        .drawing-figure {
            margin-top: 10px;
            display: flex;
        }

        .figure {
            width: 100%;
            padding-bottom: 70.7%;
            position: relative;
            overflow: hidden;
            background-color: #d8d8d8;
            border: 1px solid #d8d8d8;
            border-radius: 8px;
    
            >img {
                top: 0;
                left: 0;
                position: absolute;
            }
        }
    }

}
@primary-color: #0168B3;@text-color: #222a53;

.quantity-query-container {
    // background-color: white;
    // padding: 10px;
    // min-height: 700px;

    display: flex;
    align-items: stretch;
    padding-bottom: 20px;
    min-height: calc(~'100vh - 100px');

    .query-select {
        background-color: white;
        padding: 10px;
        // min-height: 700px;
        // height: 100%;
        flex: 0 0 200px;
        width: 200px;
        margin-right: 10px;

        .ant-tree-indent-unit {
            width: 6px;
        }
    }

    .query-main {
        background-color: white;
        // height: 100%;
        // min-height: 700px;
        padding: 10px;

        .header-operations {
            display: flex;
            flex-wrap: wrap;
            .operation-item {
                margin-bottom: 10px;
            }
            // justify-content: space-between;
        }

        .data-content {
            margin-top: 20px;
        }
    }
}

.quantity-query-filter-menu {
    .ant-dropdown-menu-item {
        padding: 5px 20px;
    }
}
@primary-color: #0168B3;@text-color: #222a53;

.backend-users-container {
    .page-header {
        margin-bottom: 10px;
    }
    .user-add {
        background-color: white;
        padding: 10px 0;
    }

    .operations {
        margin-bottom: 10px;
        padding-left: 5%;
    }

    .main {
        background-color: white;
        padding: 10px;
    
        .ant-table-thead > tr > th {
            padding: 2px 16px;
            background: #eef1f6;
            // border: 1px solid #cecdcd;
        }
    
        .ant-table-tbody > tr > td {
            padding: 6px 16px;
        }
    
        .editable-cell {
            position: relative;
          }
          
          .editable-cell-value-wrap {
            padding: 5px 12px;
            cursor: pointer;
            &:hover {
                border: 1px solid #d9d9d9;
                border-radius: 4px;
                padding: 4px 11px;
            }
          }
    }
}
@primary-color: #0168B3;@text-color: #222a53;

.project-log-container {
    background-color: white;
    padding: 10px;

    .header {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
    }

    min-height: calc(~'100vh - 100px');

    .ribbon-row {
        background: #f6f7fa;
    }

    .ant-table-thead > tr > th {
        padding: 8px 16px;
        background: #eef1f6;
    }

    .ant-table-tbody > tr > td {
        padding: 6px 16px;
        border-color: #dadadada;
    }
}
@primary-color: #0168B3;@text-color: #222a53;
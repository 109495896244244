.sensor-add-container {
    padding: 10px;
    border: 1px solid #eaeaea;
    margin: 0 16px 12px;
    border-radius: 4px;

    .sensor-work-radio.ant-radio-group-solid  {
        .ant-radio-button-wrapper:nth-of-type(1).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
            background: #B3B5B9;
            border-color: #B3B5B9;
        }
    }
    .sensor-work-radio.ant-radio-group-solid  {
        .ant-radio-button-wrapper:nth-of-type(2).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
            background: #009547;
            border-color: #009547;
            &::before {
                background-color: #009547;
            }
        }
    }
}
@primary-color: #0168B3;@text-color: #222a53;
@button-color: #222a53;
@button-hover: #384479;

.dashboard-container {
    .dashboard-card {
        background-color: white;
        margin-top: 10px;
        box-shadow:0 2px 4px 0 rgba(220,220,220,0.50);

        &.project, &.evaluate {
            height: 400px;
            padding-top: 10px;
        }
        &.quantity {
            // padding-top: 10px;
            padding: 0 20px;
            height: 390px;
            .extra-button-group {
                
                .ant-btn-primary {
                    background-color: @button-color;
                    border-color: @button-color;
                    &:hover {
                        background-color: @button-hover;
                        border-color: @button-hover;
                    }
                }
            }
        }

        &.process, &.device {
            height: 170px;
            padding-top: 10px;
        }

        &.alert {
            height: 230px;
            padding: 0 12px;
        }

        &.report {
            height: 200px;
            padding-top: 4px;
            // padding: 0 12px;

            // >.header {
            //     display: flex;
            //     justify-content: space-between;
            //     border-bottom: 1px solid #f0f0f0;
            //     align-items: center;
            //     margin-bottom: 4px;

            //     >span {
            //         display: block;
            //         padding: 12px 0;
            //         font-size: 16px;
            //         font-weight: 500;
            //     }
            // }

        }

        >.title {
            border-left: 3px solid @text-color;
            padding-left: 9px;
            font-size: 16px;
            margin-left: 2px;
            font-weight: bold;
        }

        .device-group {
            display: flex;

            >div {
                width: 50%;
                position: relative;
            }
        }
    }
}

@media screen and (max-width:1440px) {
    .dashboard-card.report  {
        height: 230px;
    }
}
@primary-color: #0168B3;@text-color: #222a53;
.data-table-container {
    margin-top: -10px;
    // height: calc(~'100vh - 200px');
    // overflow: auto;
    // width: 1400px;
    // max-width: 1400px;
    width: calc(~'100vw - 480px');
    .ant-table-thead > tr > th {
        padding: 8px 16px;
        background: #eef1f6;
    }

    .ant-table-tbody > tr > td {
        padding: 4px 16px;
    }
}
@primary-color: #0168B3;@text-color: #222a53;

.constage-list-container {
    .header {
        text-align: right;
        margin-bottom: 10px;
    }

    .upload-file {
        margin-left: 26px;
        margin-bottom: 10px;
    }

    .content {
        padding-top: 10px;
    }
    .ant-timeline-item-tail {
        border-left-color: #7cb5df;
        top:20px;
    }
    .ant-timeline-item {
        padding-bottom: 15px;
    }
    .ant-timeline-item-head {
        top: 10px
    }

    .item-group {
        display: flex;
        align-items: stretch;
        height: 100%;
    }

    .delete-btn {
        height: 100%;
        width: 50px;
        transform: all .3s;
    }

    .constage-list-item {
        background-color: white;
        box-shadow: 0 2px 6px 2px rgba(230,230,230,0.50);
        cursor: pointer;
        padding: 10px 20px;
        min-height: 120px;

        &:hover {
            background-color: #f7fbfd;
        }
        .item-main {
            display: flex;
            margin-bottom: 10px;
        }
        .item-head {
            // display: flex;
            margin-right: 20px;
            min-width: 280px;
            >div {
                font-size: 16px;
                font-weight: 600;
                margin-right: 10px;
            }
        }

        .item-body {
            margin-right: 20px;
            .title {
                padding-left: 4px;
                border-left: 2px solid @text-color;
                // border-bottom: 1px solid #eaeaea;
                line-height: 1;
                margin-bottom: 6px;
            }
        }

        .figures {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .figure-shell {
                flex:  0 170px;
                position: relative;
                cursor: pointer;
                &:hover {
                    .figure::before, .figure-actions {
                        opacity: 1;
                    }
                }

                .figure-actions {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    z-index: 10;
                    white-space: nowrap;
                    transform: translate(-50%,-50%);
                    opacity: 0;
                    transition: all .3s;
        
                    .eye {
                        color: rgba(255,255,255,0.85);
                        font-size: 28px;
                        cursor: pointer;
                        margin: 0 16px;
                    }
                }
            }
            .figure {
                width: 100%;
                padding-bottom: 56.25%;
                position: relative;
                overflow: hidden;
                background-color: #d8d8d8;
                border-radius: 8px;
                border: 1px solid #d8d8d8;
                // margin: 0 10px;
                &::before {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0,0,0,0.5);
                    opacity: 0;
                    z-index: 1;
                    transition: all, .3s;
                    content: ' ';
                }
                >img {
                    top: 0;
                    left: 0;
                    position: absolute;
                    // border-radius: 8px;
                }
            }
        }
    }
}
@primary-color: #0168B3;@text-color: #222a53;
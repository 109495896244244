.sider {

    .ant-menu-submenu {
        .ant-menu-submenu-title {
            height: 36px !important;
            line-height: 36px !important;
        }
        .tn-menu-item {
            height: 28px !important;
            line-height: 28px !important;
        }
    }
    .tn-menu-item {
        height: 36px !important;
        line-height: 36px !important;
    }
    .ant-menu {
        background-color: transparent;
    }
    // .ant-menu-inline {
    //     // border-right: 1px solid transparent;

    //     .ant-menu-submenu-arrow::before, .ant-menu-submenu-arrow::after {
    //         background: linear-gradient(to right, #fff, #fff);
    //     }

    //     .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow {
    //         &::before, &::after {
    //             background: linear-gradient(to right, #fff, #fff);
    //         }
    //     }
    // }
    .ant-menu-dark .ant-menu-inline.ant-menu-sub {
        background: #1c2244;
    }
    .ant-menu-item>a, .ant-menu-submenu-title {
        color: rgba(255,255,255,.8);
        // &:hover {
        //     color: white;
        // }
    }
    // .ant-menu-item, .ant-menu-submenu-title {
    //     &:active {
    //         background: transparent;
    //     }

    // }
}
@primary-color: #0168B3;@text-color: #222a53;